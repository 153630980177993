/* GENERAL STYLE
#nav {
    background: green;
    height: 50px;
    width: 100%;
  }
  
   .category {
    position: relative;
    background: red;
  }
  
   .category:hover {
    background-color: blue;
  }
  
   
  #nav li {
    float: left;
    line-height: 50px;
    padding: 0 10px;
    margin-top: -1px;
    border: 1px solid white;
  }
  
 
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
    color: #fff;
  }
  
  
  a {
    text-decoration: none;
    color: #fff;
  }
  
  .dropdown {
    display: none;
    position: absolute;
    background: yellow;
    padding: 10px;
  }
  
  .category:hover > .dropdown {
    display: block;
  }
  
   .dropdown li {
    position: relative;
    background: orange;
    margin: 0;
    padding: 10px;
    text-align: center;
    text-transform: capitalize;
  }
  
  .dropdown-subcategory {
    white-space: nowrap;
  }
  
   .dropdown-subcategory .dropdown {
    display: none;
    position: absolute;
    top: 0%;
    left: 100%;
    background: red;
    text-align: center;
  }
  .active{
    border: 2px solid red;
    color: red!important;
    background-color: white;
  }  
  
  .dropdown li:hover ul {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }
  
  #nav .subcategory-item {
    background: green;
    color: #000;
    margin: 0;
    padding: 0;
    position: relative;
    width: 100%;
  }
   */


   .activeK{
    border: 2px solid #7aaa74 !important;
    color: #59aa4e!important;
    background-color: white;
    box-shadow: 2px 2px 8px #66995f;
   }