.filter-icon1 {
    cursor: pointer;
}

.filter-icon2 {
    cursor: pointer;
    display: none;
}

.pro-title-large {
    font-size: 1.5rem;
}

.pro-price-large {
    font-size: 1rem;
    padding: .3rem;
}

.pro-rating-icon-large {
    color: #fd7e14;
    font-size: 0.7rem;
    padding: 0.3rem;
}

.heart-icon-large {
    font-size: 20px;
    color: #C4C4C4;
}

@media (max-width: 575.98px) {
    .filter-icon2 {
        display: block;
    }
    .filter-icon1 {
        display: none;
    }
}