.paginationButtons {
    width: 80%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: center;
}

.paginationButtons a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    color: black;
    cursor: pointer;
}

.paginationButtons a:hover {
    color: white;
    background-color: #67AC65;
}

.paginationActive a {
    color: white;
    background-color: #67AC65;
}