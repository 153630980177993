  * {
    box-sizing: border-box;
    
  }
  
  
  
  .app {
    margin: 2rem;
  }
  
  a {
    color: #08c;
  }
  
  code {
    background: #eee;
    padding: .1rem;
    font-family: 'Menlo';
    font-size: 13px;
    color: #ff00aa;
  }
  
  input {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  input:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
      0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
  }
  
  input.error {
    border-color: red;
  }
  
  label {
    font-weight: bold;
    display: block;
    margin-bottom: .5rem;
  }
  
  .input-feedback {
    color: red;
    margin-top: .25rem;
  }
  
  button {
    max-width: 150px;
    margin: 20px 0;
    padding: 12px 20px;
    border-style: none;
    border-radius: 5px;
    background-color: #08c;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    font-size: 17px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
  }
  
  button:disabled {
    opacity: .5;
    cursor: not-allowed !important;
  }
  
  button + button {
    margin-left: .5rem;
  }
  
  button.outline {
    background-color: #eee;
    border: 1px solid #aaa;
    color: #555;
  }
  


   /* body {
    background: #0e101c;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif;
  }
  
  form {
    max-width: 500px;
    margin: 0 auto;
  }
  
  h1 {
    font-weight: 100;
    color: white;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(79, 98, 148);
  }
  
  .form {
    background: #0e101c;
    max-width: 400px;
    margin: 0 auto;
  }
  
  p {
    color: #bf1650;
  }
  
  p::before {
    display: inline;
    content: "⚠ ";
  }
  
  input {
     display: block; 
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgb(65, 177, 55);
    padding: 10px 15px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  
  label {
    line-height: 2;
    text-align: left;
    display: block;
    margin-bottom: 13px;
    margin-top: 20px;
    color: rgb(24, 2, 2);
    font-size: 14px;
    font-weight: 200;
  }
  
  button[type="submit"],
  input[type="submit"] {
    background: #5aec47;
    color: white;
    text-transform: uppercase;
    border: none;
    margin-top: 40px;
    padding: 20px;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 10px;
  }
  
  button[type="submit"]:hover,
  input[type="submit"]:hover {
    background: #62bf16;
  }
  
  button[type="submit"]:active,
  input[type="button"]:active,
  input[type="submit"]:active {
    transition: 0.3s all;
    transform: translateY(3px);
    border: 1px solid transparent;
    opacity: 0.8;
  }
  
  input:disabled {
    opacity: 0.4;
  }
  
  input[type="button"]:hover {
    transition: 0.3s all;
  }
  
  button[type="submit"],
  input[type="button"],
  input[type="submit"] {
    -webkit-appearance: none;
  }
  
  .App {
    max-width: 600px;
    margin: 0 auto;
  }
  
  button[type="button"] {
    display: block;
    appearance: none;
    background: #333;
    color: white;
    border: none;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 4px;
  }
  
  hr {
    margin-top: 30px;
  }
  
  button {
    display: block;
    appearance: none;
    margin-top: 40px;
    border: 1px solid #333;
    margin-bottom: 20px;
    text-transform: uppercase;
    padding: 10px 20px;
    border-radius: 4px;
  }
   */