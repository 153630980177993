.card-head{
    background: #B8DDB8 !important;
}
.card-height{
    height:40px
}
.card-border{
    border:1 px solid black !important;
}
// new navbar
nav ul{
    position: relative;
    list-style-type: none;
    padding-left: 0px;
}
.category-Card-box nav ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 10px 30px;
    height: 50px;
    transition: .5s ease;
    font: 1.25em;
    color: #000;
}
 nav ul li .dropdown-hover:hover{
   background-color:#b8ddb8;
    color: black !important;
}
 nav ul .dropdown-card {
    position: absolute;
    left: 309px;
    width: 300px;
    top: 0px;
    padding-left: 20px;
    display:none;
    background: rba(155,39, 176, .4);
    // border-radius: 1px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
    background-color: white;
    z-index: 1;
 }
 nav ul ul span{
    position: absolute;
    right: 20px;
    font-size: 1.5rem;
 }
 nav ul .dropdown{
    position: relative;
 }
 nav ul .dropdown:hover > ul{
    display: initial;
 }
.list-arrow{
    font-size: 30px;
    margin : 150px;
}
.card-top {
    border: 1px solid rgba(0, 128, 0, 0.781) !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
}
.sub-category{
  left: 295px !important;
  position: absolute;
  width: 320px;
  top: 0px;
  padding-left: 20px;
  display:none;
  background: rba(155,39, 176, .4);
  // border-radius: 1px;
box-shadow: 2px 2px 8px rgba(0, 0, 0, 1);
  background-color: white;
  z-index: 1;
}
.image-gallery-icon.image-gallery-right-nav, .image-gallery-icon.image-gallery-left-nav, .image-gallery-icon.image-gallery-fullscreen-button{
    box-shadow: none;
}
.modal-dialog.modal-fullscreen .modal-content{
    background-color: hsla(330,2%,80%,.8);
}
.modal-dialog.modal-fullscreen .btn-close {
    opacity: 1;
    background-color: white;
}
.modal-dialog.modal-fullscreen .modal-header{
    border: none;
}
.w-100{
    min-width: 100%;
}


 