input[type='number'] {
    -moz-appearance:textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg {
    height: 65px !important;
    width: 22px !important;
}

/* --------------shop by categorry sidebar */
 
/* #accordionFlushExample .accordion-item{
    position: relative!important;
}
#accordionFlushExample .accordion-header:hover + .accordion-collapse.collapse  {
display:block!important;
visibility:visible!important;
opacity: 1!important;
position:absolute; top:0; left:calc(100% - 30px);  z-index:2000; border:1px solid red;
} */