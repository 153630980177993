.cursorPointer{
    cursor: pointer;
}

.custom-input-width .css-2b097c-container{
    min-width: 130px;

}

.category-Card-box .card-body.border{
     width: 100%;
     max-height: 380px;
     overflow-y: auto;
}
.category-Card-box .card-body.border .dropdown .text-dark{
     width: 100%;
    display: flex;
    justify-content: space-between;
}
.navbar.navbar-expand-lg.bg-white.navbar-light .navbar-nav .nav-link{
  padding: 16px 10px;
  /* border: 1px solid red; */
  
}

 
.slider-custom-space .slick-slide {
    text-align:center;
    /* margin-right:10px;
    margin-left:10px; */
}
/* .slider-custom-space .slick-slide + div{
 padding: 0px 10px;
} */
.slider-custom-space .slick-slide{
    padding: 0px 10px;
}
.shadow-sm.rounded-3{
    border:1px solid #dee2e6;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.image-gallery-content.bottom.fullscreen .image-gallery-image{
    height: 90%;
}
.image-gallery-content .image-gallery-slide .image-gallery-image{
    height: 291px;
}
.accordion-button{
    min-width: 100%;
}

.accordion-body .form-check .form-check-label{
    font-weight: 400;
}
/* 
.css-26l3qy-menu{
    background-color: white!important;
    position: absolute;
     bottom:0;
     width: 100%;
     max-height: 260px!important;
     height: 260px!important;
     overflow: hidden;
   
} */