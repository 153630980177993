.cost-font{
    font-size:11px;
 }
 .para-font{
    font-size: 12px;
 }
  .img-text{
   font-size: 10px;
  }
  .text-truncate2 {
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
}
.card-shadow{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
}
.feature{
    width: 50% !important;
    display: inline-block;
}