/* .slick-arrow.slick-prev,
.slick-arrow.slick-next{
  
} */

.four-product-carousel .card {
  margin: 0 10px;
}

.product-carousel .card {
  margin: 0 10px;
}
.testimonial-carousel .card {
  margin: 0 10px;
}

.product-carousel .slick-prev:before,
.product-carousel .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black !important;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.four-product-carousel .slick-prev:before,
.four-product-carousel .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.testimonial-carousel .slick-prev:before,
.testimonial-carousel .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.product-item-images .owl-nav {
  position: absolute;
  top: 50%;
  bottom: 0;

  transform: translateY(-50%);
}

.product-item-images .slick-prev:before,
.product-item-images .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  width: 25px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  z-index: 3;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.four-products-btn .slick-prev:before,
.four-products-btn .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dropdown-item{
  -webkit-line-clamp: 1;
 overflow: hidden;
 text-overflow: ellipsis;
 display: inherit;
 -webkit-box-orient: vertical;
 font-size: 0.9rem;
}

.product-item-images .slick-prev:before, .product-item-images .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: black;
  width: 25px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%) !important;
  z-index: 3;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  top: -28px;
  position: absolute;
}


